/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<circle cx="6" cy="4" r="3" />
			<path strokeDasharray="2 2 500 2" d="M3 32v-9H1v-7a5 5 0 018.05-4" />
			<path strokeDasharray="48 2 2 2 2 2" d="M9 32v-6" />
			<circle cx="16" cy="4" r="3" />
			<path
				strokeDasharray="2 2 500 2"
				d="M13 32v-9h-2v-7a5 5 0 0110 0v7h-2v9"
			/>
			<circle cx="26" cy="4" r="3" />
			<path d="M31 20v-2M31 22v3h-7M23 12a5 5 0 018 4M28 28v4M24 32v-4" />
		</g>
	</Fragment>,
	32,
	'UsersAndCareers1Asset20'
);
