import Cookies from 'js-cookie';

export const CONSENT_COOKIE = 'zoe-cookies-consent';

export const getIsGTagAvailable = () => typeof gtag !== 'undefined';

export const getConsent = () => {
	const rawValue = Cookies.get(CONSENT_COOKIE);
	const userShouldFillConsent = getIsGTagAvailable() ? !rawValue : false;
	const value = Number(rawValue);

	const consent = value === 10 ? 'denied' : value === 11 ? 'granted' : null;
	return {
		userShouldFillConsent,
		...(!userShouldFillConsent
			? {
				ad_storage: consent,
				analytics_storage: consent,
				ad_user_data: consent,
				ad_personalization: consent,
			}
			: {}),
	};
};

/**
 * Flow:
 * next visit - previously granted
 * gtag(
 *  "consent",
 *  "default",
 *  {ad_storage: "granted", analytics_storage: "granted"}
 * )
 *
 * next visit - previously denied
 * gtag(
 * 	 "consent",
 * 	 "default",
 * 	 {ad_storage: "denied", analytics_storage: "denied"}
 * )
 *
 * - first visit - grant
 * gtag(
 *   "consent",
 *   "default",
 *   {ad_storage: "denied", analytics_storage: "denied"}
 * )
 *
 * gtag(
 *   "consent",
 *   "update",
 *   {ad_storage: "granted", analytics_storage: "granted"}
 * )
 *
 * dataLayer.push({
 *  	event: "consent_updated", level: ["marketing", "analytics"]
 * })
 *
 * - first visit - deny
 * gtag(
 *   "consent",
 *   "default",
 *   {ad_storage: "denied", analytics_storage: "denied"}
 * )
 */
export const updateConsent = ({ ad_storage, analytics_storage }) => {
	if (!getIsGTagAvailable()) {
		return;
	}
	const current = getConsent();

	const settingsNotEqual =
		analytics_storage !== current.analytics_storage ||
		ad_storage !== current.ad_storage;

	const isNewlyGranted =
		(analytics_storage !== current.analytics_storage &&
			analytics_storage === 'granted') ||
		(analytics_storage !== current.ad_storage && ad_storage === 'granted');

	const grantedExist =
		analytics_storage === 'granted' || ad_storage === 'granted';

	if (current.userShouldFillConsent || settingsNotEqual) {
		Cookies.set(CONSENT_COOKIE, analytics_storage === 'granted' ? 11 : 10, {
			expires: 360,
		});
	}

	if (
		(current.userShouldFillConsent && grantedExist) ||
		(!current.userShouldFillConsent && settingsNotEqual)
	) {
		window.gtag('consent', 'update', {
			analytics_storage,
			ad_storage,
			ad_user_data: ad_storage,
			ad_personalization: ad_storage,
		});
	}

	if (
		(current.userShouldFillConsent && grantedExist) ||
		(!current.userShouldFillConsent && isNewlyGranted)
	) {
		window.dataLayer.push({
			event: 'consent_updated',
			level: ['marketing', 'analytics'],
		});
	}
};

export const grantAll = () => {
	updateConsent({
		ad_storage: 'granted',
		analytics_storage: 'granted',
	});
};

export const updateSelection = ({ analytic }) => {
	const consent = analytic ? 'granted' : 'denied';
	updateConsent({
		ad_storage: consent,
		analytics_storage: consent,
	});
};
