/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(0 0.5) scale(1 1)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M18.81 16l7.78 7.78a2 2 0 002.83 0h0a2 2 0 000-2.83L17 8.51l-4.71 1.68A2.61 2.61 0 019 8.79h0a2.62 2.62 0 011.22-3.41L15 3M14 28.14l1.27 1.28a2 2 0 002.84 0h0a2 2 0 000-2.82" />
				<path d="M18.1 26.59l-.1-.1 2.93 2.93a2 2 0 002.84 0h0a1.93 1.93 0 000-2.74L16 18.81M16 18.81L25.17 28A2 2 0 0028 28h0a2 2 0 000-2.76L18.81 16M2.09 20.43h0a2 2 0 010-2.83L3.67 16a2 2 0 012.83 0h0a2 2 0 010 2.83l-1.58 1.6a2 2 0 01-2.83 0z" />
				<path d="M4.92 23.26h0a2 2 0 010-2.83l3.34-3.34a2 2 0 012.83 0h0a2 2 0 010 2.82l-3.34 3.35a2 2 0 01-2.83 0z" />
				<path d="M7.75 26.09h0a2 2 0 010-2.83l1.57-1.58a2 2 0 012.83 0h0a2 2 0 010 2.83l-1.58 1.58a2 2 0 01-2.82 0z" />
				<path d="M10.57 28.91h0a2 2 0 010-2.82l1.58-1.58a2 2 0 012.83 0h0a2 2 0 010 2.82l-1.58 1.58a2 2 0 01-2.83 0z" />
				<path
					strokeDasharray="38 2 2 2 2 2"
					d="M3.63 16.06L3.57 16A8.79 8.79 0 0115.86 3.44l.14.13.33-.31a8.77 8.77 0 013.38-1.9h0A8.79 8.79 0 0128.43 16l-2 2"
				/>
			</g>
		</g>
	</Fragment>,
	32,
	'CharityAndEcology1Asset25'
);
