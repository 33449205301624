/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const IconZoeBaas = forwardRef((props, ref) => (
	<SvgIcon viewBox="0 0 24 24" ref={ref} {...props}>
		<path
			className="body"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.49988 17.1811L9.72166 15.1733L10.1728 16.6161L3.95105 18.6238L3.49988 17.1811ZM14.6979 15.1755L21.2353 17.3542V19.8684H19.7451V18.4507L14.2334 16.6139L14.6979 15.1755ZM6.94294 23.0852V19.4082H8.43313V23.0852H6.94294ZM15.9798 20.8145V19.4082H17.47V20.8145H15.9798Z"
		/>
		<path
			className="border"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.02423 5.41044L3.02414 5.41047C2.75127 5.49203 2.49715 5.62815 2.27669 5.81087C2.05622 5.99359 1.87384 6.21924 1.74028 6.47459C1.60671 6.72995 1.52465 7.00987 1.49894 7.29792C1.47323 7.58596 1.50437 7.87631 1.59054 8.15194C1.59054 8.15195 1.59055 8.15196 1.59055 8.15197L5.5989 20.972L5.59892 20.972C5.76818 21.5134 6.13934 21.9658 6.63269 22.2322C7.12601 22.4987 7.70234 22.5582 8.23824 22.398C8.23826 22.398 8.23828 22.398 8.2383 22.398L20.9758 18.5896L20.9759 18.5895C21.2487 18.508 21.5028 18.3719 21.7233 18.1891C21.9438 18.0064 22.1262 17.7808 22.2597 17.5254C22.3933 17.2701 22.4753 16.9901 22.5011 16.7021C22.5268 16.414 22.4956 16.1237 22.4095 15.848L18.4011 3.02802L18.4011 3.02797C18.2318 2.48656 17.8607 2.03423 17.3673 1.76776C16.874 1.50131 16.2977 1.44184 15.7618 1.60202C15.7617 1.60203 15.7617 1.60204 15.7617 1.60204L3.02423 5.41044ZM15.341 0.149837L2.60346 3.95825C2.14021 4.09673 1.70884 4.32781 1.33463 4.63794C0.960431 4.94808 0.650937 5.33102 0.424302 5.76431C0.197668 6.1976 0.0584557 6.67252 0.0148318 7.16121C-0.0287921 7.6499 0.0240498 8.14252 0.170259 8.61018L4.17862 21.4302C4.46579 22.3488 5.0956 23.1165 5.93309 23.5688C6.77059 24.0212 7.74912 24.1222 8.65901 23.8502L21.3965 20.0417C21.8598 19.9033 22.2912 19.6722 22.6654 19.3621C23.0396 19.0519 23.3491 18.669 23.5757 18.2357C23.8023 17.8024 23.9415 17.3275 23.9852 16.8388C24.0288 16.3501 23.976 15.8575 23.8297 15.3898L19.8214 2.56978C19.5342 1.65122 18.9044 0.883513 18.0669 0.431166C17.2294 -0.0211805 16.2509 -0.122171 15.341 0.149837Z"
		/>
		<g className="q">
			<path d="M11.6124 13.7392V12.5447L13.1659 10.4517V9.92844C13.1659 9.8363 13.1249 9.75403 13.043 9.68163C12.961 9.60923 12.8679 9.57303 12.7635 9.57303H11.4894C11.3702 9.57303 11.2733 9.60923 11.1988 9.68163C11.1318 9.75403 11.0982 9.8363 11.0982 9.92844V10.659L10.0588 10.4517V9.36571C10.0588 9.17484 10.1371 9.0103 10.2935 8.87209C10.45 8.72729 10.64 8.65489 10.8635 8.65489H13.4006C13.6241 8.65489 13.8141 8.72729 13.9706 8.87209C14.1271 9.0103 14.2053 9.17484 14.2053 9.36571V10.659L12.6518 12.8211V13.7392H11.6124ZM11.6124 15.5656V14.6475H12.6518V15.5656H11.6124Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.2398 14.0683V12.4464L12.7933 10.3534V9.9289C12.7918 9.92665 12.7879 9.92172 12.7795 9.91433L13.043 9.68163C13.1249 9.75403 13.1659 9.8363 13.1659 9.92844V10.4517L11.6124 12.5447V13.7392H12.6518V12.8211L14.2053 10.659V9.36571C14.2053 9.17484 14.1271 9.0103 13.9706 8.87209C13.8141 8.72729 13.6241 8.65489 13.4006 8.65489H10.8635C10.64 8.65489 10.45 8.72729 10.2935 8.87209C10.1371 9.0103 10.0588 9.17484 10.0588 9.36571V10.4517L11.0982 10.659V9.92844C11.0982 9.8363 11.1318 9.75403 11.1988 9.68163C11.2733 9.60923 11.3702 9.57303 11.4894 9.57303H12.7635C12.8679 9.57303 12.961 9.60923 13.043 9.68163L12.7795 9.91433C12.7712 9.90694 12.7656 9.90349 12.763 9.90212H11.4894C11.484 9.90212 11.48 9.90238 11.4772 9.90267C11.4721 9.91092 11.4708 9.91829 11.4708 9.92844V11.0707L9.68628 10.7147L9.68628 9.36571C9.68628 9.08651 9.80457 8.83991 10.027 8.6421C10.2523 8.43505 10.5381 8.32581 10.8635 8.32581H13.4006C13.7261 8.32581 14.0119 8.43505 14.2371 8.6421C14.4596 8.83991 14.5779 9.08651 14.5779 9.36571V10.7546L13.0243 12.9167V14.0683H11.2398ZM11.2398 15.8947V14.3184H13.0243V15.8947H11.2398ZM11.6124 15.5656H12.6518V14.6475H11.6124V15.5656Z"
			/>
		</g>
		<path
			className="head"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1247 6.05517C11.9008 6.05517 11.6799 6.08088 11.466 6.13053L11.134 4.65479C11.4562 4.57999 11.7885 4.54138 12.1247 4.54138C12.4609 4.54138 12.7933 4.57999 13.1155 4.65479L12.7835 6.13053C12.5696 6.08088 12.3486 6.05517 12.1247 6.05517ZM10.6488 6.4583C10.4595 6.57068 10.2839 6.70485 10.1257 6.85881C9.97033 7.01012 9.83578 7.17673 9.72337 7.35451L8.46952 6.53643C8.64541 6.25825 8.85477 5.99944 9.09459 5.76595C9.33857 5.5284 9.60819 5.32279 9.89658 5.1515L10.6488 6.4583ZM14.1237 6.85881C13.9656 6.70485 13.7899 6.57068 13.6007 6.4583L14.3529 5.1515C14.6413 5.32279 14.9109 5.5284 15.1549 5.76595C15.3947 5.99944 15.6041 6.25825 15.78 6.53643L14.5261 7.35451C14.4137 7.17673 14.2791 7.01012 14.1237 6.85881ZM14.9358 8.76065C14.9358 8.54723 14.9096 8.33634 14.8588 8.1319L16.3037 7.76147C16.3843 8.0858 16.426 8.42108 16.426 8.76065H14.9358ZM9.39069 8.1319C9.33989 8.33634 9.31368 8.54723 9.31368 8.76065H7.82349C7.82349 8.42108 7.86521 8.0858 7.94579 7.76147L9.39069 8.1319Z"
		/>
	</SvgIcon>
));

IconZoeBaas.displayName = 'IconZoeBaas';

export default IconZoeBaas;
