/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path
					strokeDasharray="34 2 2 2 2 2"
					d="M30.79 6.36L18.8 18.35l-5.65-5.66L25.13.71"
				/>
				<path d="M18.08 17.66h0l-4.23-4.26h0a12 12 0 01-8.48 3.52h-.71L1.13 29l1.41 1.41 12-3.54v-.68a12 12 0 013.54-8.53zM1.83 29.66l4.95-4.95M8.2 23.3l1.41-1.42" />
			</g>
		</g>
	</Fragment>,
	32,
	'Editor1Asset14'
);
