/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path d="M20 24v-5h7a4 4 0 000-8h-.1a5.58 5.58 0 00.1-1 5 5 0 00-5-5 5.12 5.12 0 00-1.88.37A8 8 0 005 9a.34.34 0 000 .1A5 5 0 006 19h6v5M16 15v12M11 27h10v4H11zM21 29h7M4 29h7M0 29h2M30 29h2M16 7v2M16 11v2" />
		</g>
	</Fragment>,
	32,
	'NetvorkAndSecurity1Asset21'
);
