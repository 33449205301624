/* eslint-disable react/prop-types,react/display-name */
import React, { forwardRef } from 'react';
import { Box, Button, Container, Heading, Text } from '@fast-ai/ui-components';
import { useForm } from 'react-form';

import { grantAll, updateSelection } from '../cookieConsent';
import { P, Section, Strong } from '../ui';
import { SwitchField } from '../forms';

import data from './CookieBarData';

const defaultValues = {
	analytic: false,
	necessary: true,
};
const ManageForm = forwardRef(({ onClose }, ref) => {
	const afterSubmit = () => {
		onClose();
	};

	const onSubmit = values => {
		updateSelection(values);
		afterSubmit();
	};

	const agreeAll = () => {
		grantAll();
		afterSubmit();
	};

	const { Form } = useForm({
		defaultValues,
		onSubmit,
	});

	// NOTE: Modal gives focus to the first child. Also Form do not use
	// forwardRef so it do not support refs which modal/TrapFocus needs.
	return (
		<Box
			tabIndex="-1"
			ref={ref}
			sx={{
				height: '100%',
			}}
		>
			<Box
				as={Form}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box
					sx={{
						flexGrow: 0,
						flexShrink: 1,
						overflowY: 'scroll',
					}}
				>
					<Section styles={{ container: { textAlign: 'left' } }}>
						<Heading>Cookie policy</Heading>
						<P>
							We use cookies so that we can adjust our website to your needs and
							to enable you to better browse our website. If you continue to
							browse our website without changing the settings for the use of
							cookies in your internet browser, we will consider this as your
							consent to the use of cookies on our website.
						</P>

						{data.map(({ type, heading, description }) => (
							<Box key={heading}>
								<Heading as="h2" variant="subHeading2">
									{heading}
								</Heading>
								<P>
									Type: <Strong>{type}</Strong>
								</P>
								{description}
							</Box>
						))}
					</Section>
				</Box>
				<Box
					variant="cookieBarModelFooter"
					sx={{
						flexGrow: 1,
						flexShrink: 0,
					}}
				>
					<Container
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								alignItems: 'center',
							}}
						>
							<Box sx={{ mr: 2 }}>
								<SwitchField
									field="necessary"
									label={<Text>Necessary</Text>}
									disabled
								/>
							</Box>

							<SwitchField field="analytic" label={<Text>Analytic</Text>} />
						</Box>
						<Box
							sx={{
								ml: 2,
							}}
						>
							<Button type="submit" variant="outline" sx={{ mr: 2 }}>
								Use selection
							</Button>

							<Button
								variant="primary"
								type="submit"
								onClick={() => {
									agreeAll();
								}}
							>
								Enable all
							</Button>
						</Box>
					</Container>
				</Box>
			</Box>
		</Box>
	);
});
export default ManageForm;
