/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(0 0.5) scale(1 1)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path strokeDasharray="15 2 2 2 2 2" d="M0 1h32" />
				<path d="M0 23h32M24 27H8M29 23V1M3 23V1M7 31l2-8M25 31l-2-8" />
				<path
					strokeDasharray="24.5 2 2 2 2 2"
					d="M13 23v-4.68a7 7 0 116 0V23"
				/>
				<path d="M13 20h6M16 16v-4M13 12h6" />
			</g>
		</g>
	</Fragment>,
	32,
	'BusinessAndFinance2Asset11'
);
