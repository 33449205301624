/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path
					strokeDasharray="60 2 2 2 2 2"
					d="M9.29 9.29l7.78 17.68 2.83-4.24 7.78 7.78 2.83-2.83-7.78-7.78 4.24-2.83L9.29 9.29z"
				/>
				<path d="M8 15a7 7 0 117-7" />
			</g>
		</g>
	</Fragment>,
	32,
	'Programming1Asset67'
);
