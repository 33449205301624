/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import Careers from '@fast-ai/dashboard-icons/src/CharityAndEcology1Asset25';
import { Box, Text } from '@fast-ai/ui-components';
import Github from '@fast-ai/dashboard-icons/src/Github';
import Book from '@fast-ai/dashboard-icons/src/Book';
import ZoeDr from '@fast-ai/dashboard-icons/src/ZoeDr';
import ZoeBaas from '@fast-ai/dashboard-icons/src/ZoeBaas';
import Pen from '@fast-ai/dashboard-icons/src/Editor1Asset14';
import Pencil from '@fast-ai/dashboard-icons/src/Editor1Asset4';
import Clock from '@fast-ai/dashboard-icons/src/Clock';
import CodePaper from '@fast-ai/dashboard-icons/src/Editor1Asset19';
import Cloud from '@fast-ai/dashboard-icons/src/NetvorkAndSecurity1Asset21';
import Graph from '@fast-ai/dashboard-icons/src/BusinessAndFinance2Asset10';
import Graph2 from '@fast-ai/dashboard-icons/src/BusinessAndFinance2Asset11';
import Pointer from '@fast-ai/dashboard-icons/src/Programming1Asset67';
import References from '@fast-ai/dashboard-icons/src/UsersAndCareers1Asset20';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';

import c from '../intl/messages/common';
import n from '../intl/messages/navigation';
import r from '../intl/messages/routes';

import NavigationItemDefault from './NavigationItem';

const Subheading = ({ sx, ...rest }) => (
	<Text sx={{ fontWeight: 'bold', pt: 2, ...sx }} {...rest} />
);

const NavigationItem = props => (
	<NavigationItemDefault disableHoverBackground {...props} />
);

export default [
	{
		label: <FormattedMessage {...n.products} />,
		children: [
			{
				label: <FormattedMessage {...n.zoeDr} />,
				to: r.dr,
				icon: (
					<Box
						as={ZoeDr}
						sx={{ '.c,.border': { fill: 'primary' } }}
						size={40}
					/>
				),
			},
			{
				label: <FormattedMessage {...n.zoeBass} />,
				to: r.baas,
				icon: (
					<Box
						as={ZoeBaas}
						sx={{ '.q,.border': { fill: 'primary' } }}
						size={40}
					/>
				),
			},
		].map((props, i) => <NavigationItem key={`${i}`} {...props} />),
	},
	{
		label: 'Company',
		children: [
			<NavigationItem
				key="company-references"
				{...{
					label: <FormattedMessage {...n.references} />,
					to: r.references,
					icon: <References size={32} />,
				}}
			/>,
			<NavigationItem
				key="company-careers"
				{...{
					label: <FormattedMessage {...n.careers} />,
					to: r.careers,
					icon: <Careers size={32} />,
				}}
			/>,
			<NavigationItem
				key="careers-blog"
				{...{
					label: <FormattedMessage {...n.blog} />,
					to: r.blog,
					icon: <Book size={32} />,
				}}
			/>,
		],
	},
	{
		label: <FormattedMessage {...n.forDevelopers} />,
		children: [
			<Subheading>
				<FormattedMessage {...c.dr} />
			</Subheading>,
			...[
				{
					label: <FormattedMessage {...n.installation} />,
					to: 'https://zoe.lundegaard.ai/docs/dr/learn/s-analytics/getting-started',
					icon: <Pointer size={32} />,
				},
				{
					label: <FormattedMessage {...n.saReference} />,
					to: 'https://zoe.lundegaard.ai/docs/dr/s-analytics/api-reference',
					icon: <CodePaper size={32} />,
				},
				{
					label: <FormattedMessage {...n.apiGuides} />,
					to: 'https://zoe.lundegaard.ai/docs/dr/learn/zoe-api/getting-started',
					icon: <Pencil size={32} />,
				},
				{
					label: <FormattedMessage {...n.apiReference} />,
					to: 'https://zoe.lundegaard.ai/docs/dr/api-reference/zoe-api',
					icon: <Pen size={32} />,
				},
			].map((item, i) => <NavigationItem key={`${i}`} {...item} />),
			<Subheading>Behavior-as-a-Service</Subheading>,
			...[
				{
					label: <FormattedMessage {...n.documentation} />,
					to: 'https://zoe.lundegaard.ai/docs/',
					icon: <Book size={32} />,
				},
				{
					label: <FormattedMessage {...n.saGettingStarted} />,
					to: 'https://zoe.lundegaard.ai/docs/baas/s-analytics/learn/installation/getting-started',
					icon: <Clock size={32} />,
				},
				// {
				// 	label: 'S-Analytics API reference',
				// 	to: 'https://zoe.lundegaard.ai/docs/s-analytics/reference',
				// 	icon: <CodePaper size={32} />,
				// },
				// {
				// 	label: 'Specs',
				// 	to: 'https://zoe.lundegaard.ai/docs/s-analytics/specs',
				// 	icon: <Pencil size={32} />,
				// },
				{
					label: <FormattedMessage {...n.zoeApi} />,
					to: 'https://zoe.lundegaard.ai/docs/baas/zoe-api/api-reference',
					icon: <Cloud size={32} />,
				},
				// {
				// 	label: 'Zoe.ai API reference',
				// 	to: 'https://zoe.lundegaard.ai/docs/zoe-api/swagger/',
				// 	icon: <Pen size={32} />,
				// },
				{
					label: <FormattedMessage {...n.smartFeatures} />,
					to: 'https://zoe.lundegaard.ai/docs/baas/zoe-api/api-reference/features',
					icon: <Graph size={32} />,
				},
				{
					label: <FormattedMessage {...n.smartSignals} />,
					to: 'https://zoe.lundegaard.ai/docs/baas/zoe-api/api-reference/smart-signals',
					icon: <Graph2 size={32} />,
				},
				{
					label: <FormattedMessage {...n.openSource} />,
					to: 'https://github.com/lundegaard',
					icon: <Github size={32} />,
				},
			].map((item, i) => <NavigationItem key={`${i}`} {...item} />),
		],
	},
];
