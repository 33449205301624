/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(4 0) scale(1 1)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M4 25h19v6H4a3 3 0 01-3-3h0a3 3 0 013-3z" />
				<path d="M1 28V4a3 3 0 013-3h19v24M5 1v24" />
			</g>
		</g>
	</Fragment>,
	32,
	'Book'
);
