import React from 'react';
import { Image } from '@fast-ai/ui-components';
import { keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

import logoSrc from './assets/zoe-ai-logo.svg';
import logoSrcW from './assets/zoe-ai-logo-w.svg';

// NOTE: do not use 100% keyframe for "filter" property.
// the mobile browsers will not animate it properly
const jumpInFromTop = keyframes`
	0% {
		transform: translateY(-200px) scaleY(2.5) scaleX(.2);
		transform-origin: 50% 0%;
		filter: blur(40px);
		opacity: 0.1;
	}
	90% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
`;

const easing = 'cubic-bezier(.455, .030, .515, .955)';

const ZoeLogo = ({ type, disableAnimation, ...rest }) => (
	<Image
		height={[36, 36, 50]}
		title="Zoe.ai"
		alt="Zoe.ai logo"
		src={type === 'white' ? logoSrcW : logoSrc}
		sx={
			disableAnimation
				? {}
				: {
						filter: 'blur(0)',
						position: 'relative',
						animationName: jumpInFromTop,
						animationDuration: '.5s',
						animationTimingFunction: easing,
						animationFillMode: 'both',
						opacity: '0.1',
						animationDelay: '1.75s',
						transform: 'translateY(0) scaleY(1) scaleX(1)',
						transformOrigin: '50% 50%',
				  }
		}
		{...rest}
	/>
);
ZoeLogo.propTypes = {
	disableAnimation: PropTypes.bool,
	type: PropTypes.oneOf(['white']),
};

export default ZoeLogo;
