import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	author: { id: 'siteMetadata.author', defaultMessage: 'Lundegaard a.s.' },
	title: {
		id: 'siteMetadata.title',
		defaultMessage: 'Zoe.ai Deep recommendation',
	},
	description: {
		id: 'siteMetadata.description',
		defaultMessage:
			'Zoe.ai provides fully individualized experience through AI Deep recommendation',
	},
	logoTitle: { id: 'siteMetadata.logoTitle', defaultMessage: 'Zoe.ai' },
	homeTitle: { id: 'home.title', defaultMessage: 'Zoe.ai' },
	footerPerex: {
		id: 'site.footerPerex',
		defaultMessage:
			'Fully individualized experience through AI Deep recommendation.',
	},
	allRightsReserved: {
		id: 'site.allRightsReserved',
		defaultMessage: 'All rights reserved by',
	},
	providedBy: {
		id: 'site.providedBy',
		defaultMessage: 'Services provided by Aguan s.r.o.',
	},
});
