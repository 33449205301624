import React, { forwardRef, useMemo } from 'react';
import {
	RadioGroupField as FARadioGroupField,
	SelectField as FASelectField,
	SwitchField as FASwitchField,
	TextField as FATextField,
	getDisplayName,
} from '@fast-ai/ui-components';
import { splitFormProps, useField } from 'react-form';

const wrapField =
	({ getOnChange, getProps } = {}) =>
	Comp => {
		const Field = forwardRef((props, ref) => {
			const [field, fieldOptions, rest] = splitFormProps(props);

			const {
				meta: { error, isTouched },
				setValue,
				getInputProps: reactFormGetInputProps,
			} = useField(field, fieldOptions);

			const onChange = useMemo(
				() => (getOnChange ? getOnChange(setValue) : undefined),
				[setValue]
			);

			const inputProps = reactFormGetInputProps({
				ref,
				name: field,
				onChange,
				...rest,
			});

			const hasError = !!error && isTouched;

			return (
				<Comp
					{...(getProps ? getProps(inputProps) : inputProps)}
					hasError={hasError}
					hint={hasError && error}
				/>
			);
		});
		Field.displayName = `Field(${getDisplayName(Comp)})`;

		return Field;
	};

export const TextField = wrapField()(FATextField);
export const SelectField = wrapField()(FASelectField);
export const RadioGroupField = wrapField()(FARadioGroupField);
export const SwitchField = wrapField({
	getOnChange: setValue => event => setValue(event.target.checked),
	getProps: ({ value, ...rest }) => ({ checked: value, ...rest }),
})(FASwitchField);
