/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path strokeDasharray="39 2 2 2 2 2" d="M31 5H1V1h30z" />
			<path d="M29 15V5M3 5v22h11" />
			<circle cx="24" cy="24" r="7" />
			<path d="M24 17v7l5 5M7 21l5-7 3 3 6-8h5M6 9h8" />
		</g>
	</Fragment>,
	32,
	'BusinessAndFinance2Asset10'
);
