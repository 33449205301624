import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@fast-ai/ui-components';

import { Link } from '../intl';

const NavigationItem = forwardRef(
	(
		{
			isActive,
			label,
			sx,
			to,
			description,
			icon,
			disableHoverBackground,
			renderRoot: Root = to ? Link : 'div',
			...rest
		},
		ref
	) => (
		<Root
			ref={ref}
			tabIndex={to ? void 0 : 0}
			to={to}
			sx={{
				// variant: 'MenuItem',
				color: isActive ? 'primary' : 'body',
				textDecoration: 'none',
				textAlign: 'center',
				alignItems: 'center',
				width: '100%',
				display: 'inline-flex',
				flexWrap: 'wrap',
				py: [2, 2, 3],
				outlineOffset: '-2px',
				boxSizing: 'border-box',
				position: 'relative',
				':hover,:focus': {
					outlineOffset: '-2px',
					color: 'primary',
					backgroundColor: disableHoverBackground ? 'transparent' : 'gray.1',
				},
				'&:hover span:last-of-type,&:focus span:last-of-type': {
					display: 'block',
				},
				...sx,
			}}
			{...rest}
		>
			<Box sx={{ width: theme => theme.MiniGuide.width }}>{icon}</Box>
			<Box sx={{ textAlign: 'left', flexGrow: 0 }}>
				<Text
					as="span"
					sx={{
						color: 'inherit',
						fontWeight: description ? 'bold' : 'normal',
					}}
				>
					{label}
				</Text>
				{description && <Text variant="small">{description}</Text>}
			</Box>
		</Root>
	)
);

NavigationItem.propTypes = {
	description: PropTypes.node,
	icon: PropTypes.node,
	isActive: PropTypes.bool,
	label: PropTypes.element,
	renderRoot: PropTypes.elementType,
	to: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			id: PropTypes.any,
			defaultMessage: PropTypes.any,
		}),
	]),
};

NavigationItem.displayName = 'NavigationItem';

export default NavigationItem;
