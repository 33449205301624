/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(2 0) scale(1 1)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path
					strokeDasharray="68 2 2 2 2 2"
					d="M1 18a13 13 0 103.81-9.19A13 13 0 001 18z"
				/>
				<path d="M11 1h6M14 1v4M22 8l3.71-3.71M13 19h2M14 16V8" />
			</g>
		</g>
	</Fragment>,
	32,
	'Clock'
);
