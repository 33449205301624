import React from 'react';
import { ThemeProvider } from '@fast-ai/ui-components';

export const createContrastTheme = currentTheme => {
	const colors = {
		...currentTheme.colors,
		body: currentTheme.colors.background,
		background: currentTheme.colors.primary,
		primary: currentTheme.colors.secondary,
		contrast: currentTheme.colors.body,
	};

	return {
		...currentTheme,
		colors,
		buttons: {
			...currentTheme.buttons,
			primary: {
				...currentTheme.buttons.primary,
				color: 'body',
				':hover': {
					...currentTheme.buttons.primary[':hover'],
					boxShadow: `inset 0 0 0 4px ${colors.primary}`,
				},
			},
			outline: {
				...currentTheme.buttons.outline,
				boxShadow: `inset 0 0 0 4px ${colors.body}`,
			},
		},
		variants: {
			...currentTheme.variants,
			stripesPrimary: {
				...currentTheme.variants.transparentStripes,
				backgroundColor: colors.background,
			},
		},
	};
};

export const ContrastThemeProvider = props => (
	<ThemeProvider theme={createContrastTheme} {...props} />
);
