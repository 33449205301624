import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	baas: { id: 'common.baas', defaultMessage: 'Behavior-as-a-Service' },
	dr: { id: 'common.dr', defaultMessage: 'Deep recommendation' },
	openInNewWindow: {
		id: 'ui.openInNewWindow',
		defaultMessage: 'Open in new window',
	},
	formInvalidSubmit: {
		id: 'ui.form.invalidSubmit',
		defaultMessage: 'Invalid submit. Please try it again.',
	},
});
