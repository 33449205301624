import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@fast-ai/ui-components';

const Backdrop = forwardRef(({ invisible, sx, ...rest }, ref) => (
	<Box
		ref={ref}
		sx={{
			zIndex: -1,
			position: 'fixed',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			right: 0,
			bottom: 0,
			top: 0,
			left: 0,
			backgroundColor: invisible ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
			WebkitTapHighlightColor: 'transparent',
			...sx,
		}}
		{...rest}
	/>
));

Backdrop.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	invisible: PropTypes.bool,
};

Backdrop.displayName = 'Backdrop';

export default Backdrop;
