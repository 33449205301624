import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@fast-ai/ui-components';
import ExternalLink from '@fast-ai/ui/ExternalLink';

import { Menu, MenuItem } from '../ui';
import { useTranslateMessage } from '../intl/hooks';

import navItems from './navItemsSidebar';

const SubmenuLink = ({ to, label, variant = 'text.body', sx }) => {
	const translate = useTranslateMessage();

	const linkProps = to != null ? { as: ExternalLink, to: translate(to) } : {};
	return (
		<Box
			{...linkProps}
			sx={{
				color: 'body',
				textDecoration: 'none',
				'&:hover,&:focus': {
					color: 'primary',
				},

				span: { fontSize: [5, 5] },
				...sx,
			}}
		>
			<Text as="span" variant={variant}>
				{label}
			</Text>
		</Box>
	);
};
const styles = {
	menu: {
		flexDirection: 'column',
	},
};
const SidebarNavigationContent = () => (
	<Menu sx={styles.menu}>
		{navItems.map(({ to, label, children }, i) => (
			<MenuItem
				key={i}
				sx={{
					py: [2, 3],
					borderBottom:
						i < navItems.length - 1
							? theme => theme.separatorBorder.normal
							: 'none',
				}}
			>
				{label && (
					<SubmenuLink label={label} to={to} sx={{ fontWeight: 'bold' }} />
				)}
				{children && (
					<Menu sx={styles.menu}>
						{children.map((item, j) => {
							const key = `${i}-${j}`;
							return <MenuItem key={key}>{item}</MenuItem>;
						})}
					</Menu>
				)}
			</MenuItem>
		))}
	</Menu>
);

SidebarNavigationContent.propTypes = {
	basepath: PropTypes.string,
};

export default memo(SidebarNavigationContent);
