/* eslint-disable react/prop-types,react/display-name */
import React, { forwardRef } from 'react';
import { withPrefix } from 'gatsby';
import { Flex } from '@fast-ai/ui-components';
import { IntlProxyContextProvider } from 'gatsby-theme-fast-ai-sidebar/src/intl';

import Content from './Content';
import Header from './Header';
import { LayoutProvider } from './layoutState';
import Sidebar from './Sidebar';
import Footer from './Footer';
import CookieBar from './CookieBar';

const FlexColumn = ({ sx, ...rest }) => (
	<Flex
		sx={{
			flexDirection: 'column',
			height: '100%',
			flexGrow: 1,
			...sx,
		}}
		{...rest}
	/>
);

const Root = forwardRef((props, ref) => (
	<FlexColumn
		ref={ref}
		variant="stripesPrimary"
		sx={{
			fontFamily: 'body',
			minHeight: '100vh',
			color: 'body',
		}}
		{...props}
	/>
));

const DefaultLayout = ({ children }) => {
	const basepath = withPrefix('/');
	return (
		<Root>
			<IntlProxyContextProvider>
				<LayoutProvider>
					<Header basepath={basepath} />
					<CookieBar />
					<Sidebar basepath={basepath} />
					<Content>{children}</Content>
					<Footer basepath={basepath} />
				</LayoutProvider>
			</IntlProxyContextProvider>
		</Root>
	);
};
export default DefaultLayout;
