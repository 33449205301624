import React, { Fragment } from 'react';
import { Link } from '@fast-ai/ui-components';
import { Code } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';

import { P } from '../ui';

const Types = { ANALYTIC: 'Analytic', NECESSARY: 'Necessary' };

const data = [
	{
		heading: 'zoe.lundegaard.ai',
		type: Types.NECESSARY,
		description: (
			<P>
				This website use the <Code>zoe-cookies-consent</Code> cookie to save
				your cookie consent so the this window will not show up after you agree.
			</P>
		),
	},
	{
		heading: 'Google Analytics',
		type: Types.ANALYTIC,
		description: (
			<Fragment>
				<P>
					This website uses the Google Analytics website traffic analysis
					service (from Google, Inc). Google Analytics allows you to use cookies
					to analyze how you use the website and to generate activity reports.
				</P>
				<P>
					<Link href="https://policies.google.com/terms?hl=en" target="_blank">
						Learn more
					</Link>{' '}
					about how Google uses data when you use websites.
				</P>
			</Fragment>
		),
	},
	{
		heading: 'S-Analytics',
		type: Types.ANALYTIC,
		description: (
			<Fragment>
				<P>
					This website uses the S-Analytics analysis to collect information
					about a device. The S-analytics is developed and provided by
					zoe.lundegaard.ai.
				</P>
				<P>
					<Link
						href="https://zoe.lundegaard.ai/docs/baas/s-analytics/api-reference/cookie-usage"
						target="_blank"
					>
						Learn more
					</Link>{' '}
					about how Zoe uses data when you use websites.
				</P>
			</Fragment>
		),
	},
];
export default data;
