/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M3.44 22.37l5.66 5.66" />
				<path strokeDasharray="2 2 2 2 76 2" d="M26.51.71L9.09 18.13" />
				<path d="M30.76 4.96L13.34 22.38M4.85 16.72l4.25 1.41.7 3.54 3.54.7 1.41 4.25" />
				<path d="M20.86.71L4.14 17.43 1.32 30.15l12.72-2.83 16.72-16.71" />
			</g>
		</g>
	</Fragment>,
	32,
	'Editor1Asset4'
);
