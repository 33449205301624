/* eslint-disable react/prop-types,react/display-name */
import React, { forwardRef, useRef, useState } from 'react';
import { Match } from '@reach/router';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import { Box } from '@fast-ai/ui-components';
import useForkRef from '@fast-ai/ui/utils/useForkRef';
import Popper from '@fast-ai/ui/Popper';
import ExternalLink from '@fast-ai/ui/ExternalLink';

import { Menu, MenuItem, MenuItemLabel, MenuPopperWrapper } from '../ui';
import { useTranslateMessage } from '../intl/hooks';

import navItems from './navItems';

const MenuItemPopper = ({ label, children, sx }) => {
	const buttonRef = useRef();
	const rootRef = useRef();
	const [isSubMenuOpen, setSubMenuOpen] = useState(false);

	return (
		<MenuItem
			ref={rootRef}
			role="none"
			onMouseOver={() => setSubMenuOpen(true)}
			onFocus={() => setSubMenuOpen(true)}
			onMouseOut={() => setSubMenuOpen(false)}
			onBlur={() => {
				setTimeout(() => {
					if (
						!rootRef.current ||
						!rootRef.current.contains(document.activeElement)
					) {
						setSubMenuOpen(false);
					}
				}, 0);
			}}
			sx={{
				cursor: 'default',
				...sx,
			}}
		>
			<MenuItemLabel
				ref={buttonRef}
				tabIndex={0}
				active={isSubMenuOpen}
				label={label}
				aria-haspopup
				aria-expanded={isSubMenuOpen ? 'true' : 'false'}
			/>

			<Popper
				keepMounted
				container={rootRef.current}
				anchorEl={buttonRef.current}
				isOpened={isSubMenuOpen}
				style={{ zIndex: 1200 }}
			>
				<MenuPopperWrapper>
					<Menu
						sx={{
							flexDirection: 'column',
							minWidth: theme => theme.DesktopNavigationMenu.minWidth,
						}}
					>
						{children.map((child, i) => (
							<MenuItem key={i}>{child}</MenuItem>
						))}
					</Menu>
				</MenuPopperWrapper>
			</Popper>
		</MenuItem>
	);
};

const DesktopNavigationMenu = forwardRef(
	({ basepath, ...rest }, forwardedRef) => {
		const ref = useRef();
		const rootRef = useForkRef(ref, forwardedRef);
		const translate = useTranslateMessage();
		return (
			<Box as="nav" ref={ref} {...rest}>
				<Menu role="menubar">
					{navItems.map((item, i) => {
						const translatedTo = item.to
							? withPrefix(translate(item.to))
							: null;

						const key = `${translatedTo}${i}`;
						if (translatedTo && !item.children) {
							return (
								<Match
									path={
										translatedTo === basepath
											? translatedTo
											: `${translatedTo}/*`
									}
									key={key}
								>
									{({ match }) => (
										<MenuItem role="none">
											<MenuItemLabel
												as={ExternalLink}
												key={key}
												active={!!match}
												{...item}
												to={translatedTo}
											/>
										</MenuItem>
									)}
								</Match>
							);
						}

						return (
							<MenuItemPopper
								key={key}
								{...item}
								to={translatedTo}
								containerRef={rootRef}
								aria-role="menuitem"
							/>
						);
					})}
				</Menu>
			</Box>
		);
	}
);

DesktopNavigationMenu.propTypes = {
	basepath: PropTypes.string,
};

DesktopNavigationMenu.displayName = 'DesktopNavigationMenu';

export default DesktopNavigationMenu;
