import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@fast-ai/ui-components';
import { RemoveScroll } from 'react-remove-scroll';

import getOwnerDocument from '../utils/getOwnerDocument';
import wrapEvent from '../utils/wrapEvent';
import useForkRef from '../utils/useForkRef';
import useEventCallback from '../utils/useEventCallback';
import Backdrop from '../Backdrop';
import Portal from '../Portal';
import TrapFocus from '../TrapFocus';

const Modal = forwardRef(
	(
		{
			backdropProps,
			children,
			disableBackdropClick,
			disableEscapeKeyDown,
			hideBackdrop,
			isOpened,
			onBackdropClick,
			onClose,
			onEscapeKeyDown,
			onKeyDown,
			renderBackdrop: BackdropComponent = Backdrop,
			sx,
			...rest
		},
		ref
	) => {
		const portalMountNodeRef = React.useRef(null);
		const modalRef = React.useRef(null);
		const handleRef = useForkRef(modalRef, ref);

		const handleMounted = () => {
			// Fix a bug on Chrome where the scroll isn't initially 0.
			modalRef.current.scrollTop = 0;
		};

		const handlePortalRef = useEventCallback(node => {
			portalMountNodeRef.current = node;

			if (!node) {
				return;
			}

			if (isOpened) {
				handleMounted();
			}
		});

		const handleKeyDown = wrapEvent(onKeyDown, event => {
			if (event.key !== 'Escape') {
				return;
			}

			if (onEscapeKeyDown) {
				onEscapeKeyDown(event);
			}

			if (!disableEscapeKeyDown) {
				// Swallow the event, in case someone is listening for the escape
				// key on the body.
				event.stopPropagation();

				if (onClose) {
					onClose(event, 'escapeKeyDown');
				}
			}
		});

		const handleBackdropClick = event => {
			if (event.target !== event.currentTarget) {
				return;
			}

			if (onBackdropClick) {
				onBackdropClick(event);
			}

			if (!disableBackdropClick && onClose) {
				onClose(event, 'backdropClick');
			}
		};

		const getDoc = () => getOwnerDocument(portalMountNodeRef.current);

		return (
			<Portal ref={handlePortalRef}>
				<Box
					ref={handleRef}
					sx={{
						position: 'fixed',
						zIndex: theme => theme.zIndex.modal,
						right: 0,
						bottom: 0,
						top: 0,
						left: 0,
						...sx,
					}}
					role="presentation"
					onKeyDown={handleKeyDown}
					{...rest}
				>
					{hideBackdrop ? null : (
						<BackdropComponent
							isOpened={isOpened}
							onClick={handleBackdropClick}
							{...backdropProps}
						/>
					)}
					<RemoveScroll>
						<TrapFocus getDoc={getDoc} isOpened={isOpened}>
							{children}
						</TrapFocus>
					</RemoveScroll>
				</Box>
			</Portal>
		);
	}
);

Modal.displayName = 'Modal';

Modal.propTypes = {
	backdropProps: PropTypes.object,
	children: PropTypes.node,
	disableBackdropClick: PropTypes.func,
	disableEscapeKeyDown: PropTypes.func,
	hideBackdrop: PropTypes.bool,
	isOpened: PropTypes.bool,
	onBackdropClick: PropTypes.func,
	onClose: PropTypes.func,
	onEscapeKeyDown: PropTypes.func,
	onKeyDown: PropTypes.func,
	renderBackdrop: PropTypes.elementType,
};

export default Modal;
