/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Box, Col, Row, Text } from '@fast-ai/ui-components';
import LinkedinIcon from '@fast-ai/dashboard-icons/src/Linkedin';
import Logo from '@fast-ai/ui/ZoeLogo';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';
import ExternalLink from '@fast-ai/ui/ExternalLink';

import c from '../intl/messages/common';
import n from '../intl/messages/navigation';
import r from '../intl/messages/routes';
import s from '../intl/messages/site';
import u from '../intl/messages/ui';
import {
	ButtonWithIcon,
	LanguageSwitcher,
	Menu,
	MenuItem,
	Section,
} from '../ui';
import { ContrastThemeProvider } from '../contrastTheme';
import { useTranslateMessage } from '../intl/hooks';
import { Link } from '../intl';

const devDrLinks = {
	heading: 'Deep recommendation',
	links: [
		{
			label: <FormattedMessage {...n.documentation} />,
			to: 'http://zoe.lundegaard.ai/docs/dr',
		},
		{
			label: <FormattedMessage {...n.installation} />,
			to: 'https://zoe.lundegaard.ai/docs/dr/learn/s-analytics/getting-started',
		},
		{
			label: <FormattedMessage {...n.saReference} />,
			to: 'https://zoe.lundegaard.ai/docs/dr/api-reference/s-analytics/',
		},
		{
			label: <FormattedMessage {...n.apiGuides} />,
			to: 'https://zoe.lundegaard.ai/docs/dr/learn/zoe-api/getting-started',
		},
		{
			label: <FormattedMessage {...n.apiReference} />,
			to: 'https://zoe.lundegaard.ai/docs/dr/api-reference/zoe-api',
		},
	],
};
const devBaasLinks = {
	heading: 'Behavior-as-a-Service',
	links: [
		{
			label: <FormattedMessage {...n.documentation} />,
			to: 'http://zoe.lundegaard.ai/docs/baas',
		},
		{
			label: <FormattedMessage {...n.installation} />,
			to: 'http://zoe.lundegaard.ai/docs/baas/s-analytics/learn/installation',
		},
		{
			label: <FormattedMessage {...n.zoeApi} />,
			to: 'http://zoe.lundegaard.ai/docs/baas/s-analytics/api-reference',
		},
	],
};

const productLinks = {
	links: [
		{
			label: <FormattedMessage {...c.dr} />,
			to: '/deep-recommendation',
		},
		{
			label: <FormattedMessage {...c.baas} />,
			to: '/baas',
		},
	],
};
const contactLinks = {
	links: [
		{
			label: <FormattedMessage {...n.scheduleDemo} />,
			to: r.scheduleDemo,
		},
		{
			label: <FormattedMessage {...n.contacts} />,
			to: r.contacts,
		},
	],
};
const LinkColumn = ({ heading, links }, i) => (
	<Box key={i}>
		{heading && (
			<Text sx={{ fontWeight: 'bold', mb: 2, color: 'secondary' }}>
				{heading}
			</Text>
		)}
		<Menu sx={{ flexDirection: 'column' }}>
			{links.map(({ label, to }, i) => (
				<MenuItem key={i} sx={{ mb: 2 }}>
					<Link to={to}>
						<Text>{label}</Text>
					</Link>
				</MenuItem>
			))}
		</Menu>
	</Box>
);

const FooterHeading = ({ sx, ...rest }) => (
	<Text
		sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2, ...sx }}
		{...rest}
	/>
);

// label={<RichFormattedMessage {...m.newMessage} />}
const Footer = ({ sx, ...rest }) => {
	const translate = useTranslateMessage();
	return (
		<ContrastThemeProvider>
			<Section variant="stripesPrimary" sx={sx} {...rest}>
				<Row
					sx={{
						flexWrap: 'wrap',
						'article + article': {
							mt: [4, 4, 0],
						},
					}}
				>
					<Col as="article" span={4} sx={{ textAlign: 'left' }}>
						<ExternalLink to="/">
							<Logo height={[35, 35, 56]} type="white" disableAnimation />
						</ExternalLink>

						<Text sx={{ mt: 2, fontSize: [4, 4, 5] }}>
							<FormattedMessage {...s.footerPerex} />
						</Text>

						<LanguageSwitcher sx={{ mt: 3 }} variant="outline" />

						<ButtonWithIcon
							as={ExternalLink}
							to="https://cz.linkedin.com/company/zoe-ai"
							variant="outlineSecondary"
							title="LinkedIn"
							sx={{
								mt: 3,
								display: 'inline-flex',
								padding: 0,
								borderRadius: 'circle',
							}}
							icon={<LinkedinIcon size={48} />}
						/>
					</Col>
					<Col as="article" span={[12, 12, 4]} sx={{ textAlign: 'left' }}>
						<FooterHeading>
							<FormattedMessage {...n.forDevelopers} />
						</FooterHeading>
						<LinkColumn {...devDrLinks} />
						<LinkColumn {...devBaasLinks} />
					</Col>
					<Col as="article" span={[12, 12, 4]} sx={{ textAlign: 'left' }}>
						<FooterHeading>
							<FormattedMessage {...n.products} />
						</FooterHeading>
						<LinkColumn {...productLinks} />
						<FooterHeading sx={{ mt: 4 }}>
							<FormattedMessage {...n.contactUs} />
						</FooterHeading>
						<LinkColumn {...contactLinks} />
					</Col>
				</Row>
				<Row sx={{ mt: 4 }}>
					<Col span={12}>
						<Paragraph sx={{ textAlign: 'left', fontFamily: 'mono' }}>
							<FormattedMessage {...s.allRightsReserved} />{' '}
							<ExternalLink
								to="https://www.lundegaard.eu/en/"
								target="_blank"
								title={translate(u.openInNewWindow)}
								sx={{ span: { fontFamily: 'mono' } }}
							>
								<Text as="span">
									<FormattedMessage {...s.author} />
								</Text>
							</ExternalLink>
						</Paragraph>
						<Paragraph sx={{ textAlign: 'left', fontFamily: 'mono' }}>
							<FormattedMessage {...s.providedBy} />{' '}
						</Paragraph>
					</Col>
				</Row>
			</Section>
		</ContrastThemeProvider>
	);
};

export default Footer;
