import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, useTheme } from '@fast-ai/ui-components';
import { animated, useTransition } from '@react-spring/web';

import Modal from '../Modal';

const AnimatedModal = animated(Modal);

const Sidebar = forwardRef(
	(
		{
			children,
			variant,
			onClose,
			isOpened,
			sx,
			styles = {},
			modalProps = {},
			backdropProps = {},
			...rest
		},
		ref
	) => {
		const {
			Sidebar: { slideOffset },
		} = useTheme();

		const transition = useTransition(isOpened, {
			from: {
				opacity: 0,
				transform: `translate3d(-${slideOffset},0, 0)`,
			},
			enter: {
				opacity: 1,
				transform: 'translate3d(0px,0, 0)',
			},
			leave: {
				transform: `translate3d(-${slideOffset},0, 0)`,
				opacity: 0,
			},
		});

		const sidebar = (
			<Flex
				tabIndex="-1"
				ref={ref}
				sx={{
					outline: 0,
					borderRight: theme => theme.separatorBorder.normal,
					zIndex: 3,
					variant: 'Sidebar',
					position: 'fixed',
					backgroundColor: 'backgroundPrimary',
					touchAction: 'pan-y',
					top: 0,
					bottom: 0,
					left: 0,
					flexDirection: 'column',
					overflow: 'auto',
					WebkitOverflowScrolling: 'touch',
					...sx,
					...styles.root,
				}}
				{...rest}
			>
				{children}
			</Flex>
		);

		if (variant === 'floating') {
			return transition(
				(style, item) =>
					item && (
						<AnimatedModal
							sx={styles.modal}
							style={style}
							onClose={onClose}
							isOpened={isOpened}
							backdropProps={{
								...backdropProps,
								sx: {
									right: `-${slideOffset}`,
									...backdropProps.sx,
									...styles.backdrop,
								},
							}}
							{...modalProps}
						>
							{sidebar}
						</AnimatedModal>
					)
			);
		}

		return isOpened ? sidebar : null;
	}
);

Sidebar.propTypes = {
	backdropProps: PropTypes.object,
	children: PropTypes.node,
	isOpened: PropTypes.bool,
	modalProps: PropTypes.object,
	onClose: PropTypes.func,
	styles: PropTypes.object,
	variant: PropTypes.oneOf(['permanent', 'floating']),
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
