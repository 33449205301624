import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';
import { Box, Flex, useBreakpoint } from '@fast-ai/ui-components';
import SidebarUi from '@fast-ai/ui/Sidebar';
import LinkButton from '@fast-ai/ui/LinkButton';

import n from '../intl/messages/navigation';
import r from '../intl/messages/routes';
import { LanguageSwitcher } from '../ui';
import { Link } from '../intl';

import {
	hideSidebar,
	setSidebarVisibility,
	useLayoutState,
} from './layoutState';
import SidebarNavigationContent from './SidebarNavigationContent';

const styles = {
	sidebarUi: {
		modal: { zIndex: t => t.zIndex.sidebar },
	},
};

const SidebarSide = ({ variant = 'top' }) => (
	<Flex
		sx={{
			py: [2, 3],
			gap: 2,
			[variant === 'top' ? 'borderBottom' : 'borderTop']: theme =>
				theme.separatorBorder.normal,
			justifyContent: 'space-between',
			alignItems: 'center',
		}}
	>
		<LanguageSwitcher />

		<LinkButton
			as={Link}
			variant="primary"
			to={r.scheduleDemo}
			sx={{
				py: [2, 3],
				px: [3, 4],
				fontFamily: 'body',
			}}
		>
			<FormattedMessage {...n.scheduleDemo} />
		</LinkButton>
	</Flex>
);
const Sidebar = forwardRef(({ basepath }, ref) => {
	const [{ sidebarOpened, sidebarVariant }, dispatchToLayout] =
		useLayoutState();

	const shouldUseDesktopNavigation = useBreakpoint('md', 'up');

	useEffect(() => {
		dispatchToLayout(hideSidebar());
	}, [shouldUseDesktopNavigation, dispatchToLayout]);

	const isFloating = sidebarVariant === 'floating';
	const toggleSidebar = isOpened => event => {
		if (
			!isFloating ||
			(event.type === 'keydown' &&
				(event.key === 'Tab' || event.key === 'Shift'))
		) {
			return;
		}
		dispatchToLayout(setSidebarVisibility(isOpened));
	};

	const handleOnClose = toggleSidebar(false);

	return (
		<SidebarUi
			ref={ref}
			onClose={handleOnClose}
			variant={sidebarVariant}
			isOpened={sidebarOpened}
			styles={styles.sidebarUi}
		>
			<Box
				sx={{
					paddingTop: theme => theme.Header.height,
					px: theme => theme.grid.gutters,
				}}
			>
				<SidebarSide variant="top" />

				<SidebarNavigationContent
					basepath={basepath}
					onClick={toggleSidebar(false)}
					onKeyDown={toggleSidebar(false)}
				/>
				<SidebarSide variant="Bottom" />
			</Box>
		</SidebarUi>
	);
});

Sidebar.propTypes = {
	basepath: PropTypes.string,
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
