/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const IconZoeDr = forwardRef((props, ref) => (
	<SvgIcon viewBox="0 0 24 24" ref={ref} {...props}>
		<path
			className="c"
			d="M9.28956 15.1806C9.48058 15.7769 9.15961 16.4175 8.57265 16.6116C7.9857 16.8056 7.35502 16.4796 7.16399 15.8833C6.97297 15.2871 7.29394 14.6464 7.8809 14.4524C8.46786 14.2583 9.09854 14.5844 9.28956 15.1806Z"
		/>
		<path
			className="c"
			d="M13.5408 13.7752C13.7318 14.3715 13.4108 15.0121 12.8239 15.2062C12.2369 15.4002 11.6062 15.0742 11.4152 14.4779C11.2242 13.8817 11.5452 13.241 12.1321 13.047C12.7191 12.8529 13.3498 13.179 13.5408 13.7752Z"
		/>
		<path
			className="c"
			d="M17.7919 12.3698C17.9829 12.9661 17.6619 13.6067 17.075 13.8008C16.488 13.9948 15.8573 13.6688 15.6663 13.0725C15.4753 12.4763 15.7963 11.8356 16.3832 11.6416C16.9702 11.4475 17.6009 11.7736 17.7919 12.3698Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.0703 15.2658L6.54564 20.0676L6.08447 18.6281L20.6092 13.8263L21.0703 15.2658Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.56979 13.9219L4.76763 14.5177L4.3186 13.1161L6.12076 12.5203L6.56979 13.9219ZM11.3756 12.3331L7.77123 13.5247L7.3222 12.1231L10.9265 10.9315L11.3756 12.3331ZM16.1813 10.7443L12.577 11.9359L12.128 10.5343L15.7323 9.34276L16.1813 10.7443ZM19.1849 9.75136L17.3827 10.3472L16.9337 8.94557L18.7359 8.34979L19.1849 9.75136Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.1287 10.477L7.82355 11.2354L7.36438 9.79529L9.66953 9.03687L10.1287 10.477ZM16.2758 8.45454L11.6655 9.97138L11.2063 8.53125L15.8166 7.01441L16.2758 8.45454ZM20.1177 7.1905L17.8125 7.94892L17.3534 6.50879L19.6585 5.75037L20.1177 7.1905Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.59012 12.9627L5.6013 14.0361L5.45044 14.1998L4.30493 13.1108L5.44461 11.8738L6.59012 12.9627ZM8.48959 10.9009L7.34991 12.138L6.2044 11.049L7.34408 9.81194L8.48959 10.9009Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.3124 14.6699L17.7377 10.0891L19.1445 9.59002L20.7193 14.1708L19.3124 14.6699Z"
		/>
		<path
			className="border"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.02423 5.41044L3.02414 5.41047C2.75127 5.49203 2.49715 5.62815 2.27669 5.81087C2.05622 5.99359 1.87384 6.21924 1.74028 6.47459C1.60671 6.72995 1.52465 7.00987 1.49894 7.29792C1.47323 7.58596 1.50437 7.87631 1.59054 8.15194C1.59054 8.15195 1.59055 8.15196 1.59055 8.15197L5.5989 20.972L5.59892 20.972C5.76818 21.5134 6.13934 21.9658 6.63269 22.2322C7.12601 22.4987 7.70234 22.5582 8.23824 22.398C8.23826 22.398 8.23828 22.398 8.2383 22.398L20.9758 18.5896L20.9759 18.5895C21.2487 18.508 21.5028 18.3719 21.7233 18.1891C21.9438 18.0064 22.1262 17.7808 22.2597 17.5254C22.3933 17.2701 22.4753 16.9901 22.5011 16.7021C22.5268 16.414 22.4956 16.1237 22.4095 15.848L18.4011 3.02802L18.4011 3.02797C18.2318 2.48656 17.8607 2.03423 17.3673 1.76776C16.874 1.50131 16.2977 1.44184 15.7618 1.60202C15.7617 1.60203 15.7617 1.60204 15.7617 1.60204L3.02423 5.41044ZM15.341 0.149837L2.60346 3.95825C2.14021 4.09673 1.70884 4.32781 1.33463 4.63794C0.960431 4.94808 0.650937 5.33102 0.424302 5.76431C0.197668 6.1976 0.0584557 6.67252 0.0148318 7.16121C-0.0287921 7.6499 0.0240498 8.14252 0.170259 8.61018L4.17862 21.4302C4.46579 22.3488 5.0956 23.1165 5.93309 23.5688C6.77059 24.0212 7.74912 24.1222 8.65901 23.8502L21.3965 20.0417C21.8598 19.9033 22.2912 19.6722 22.6654 19.3621C23.0396 19.0519 23.3491 18.669 23.5757 18.2357C23.8023 17.8024 23.9415 17.3275 23.9852 16.8388C24.0288 16.3501 23.976 15.8575 23.8297 15.3898L19.8214 2.56978C19.5342 1.65122 18.9044 0.883513 18.0669 0.431166C17.2294 -0.0211805 16.2509 -0.122171 15.341 0.149837Z"
		/>
	</SvgIcon>
));

IconZoeDr.displayName = 'IconZoeDr';

export default IconZoeDr;
