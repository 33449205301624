/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment } from 'react';
import {
	Box,
	Col,
	Container,
	Flex,
	Hamburger,
	Row,
	Text,
} from '@fast-ai/ui-components';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';
import LinkButton from '@fast-ai/ui/LinkButton';
import Logo from '@fast-ai/ui/ZoeLogo';
// import ExternalLink from '@fast-ai/ui/ExternalLink';
import useScrollTrigger from '@fast-ai/ui/utils/useScrollTrigger';
import KeyboardArrowUp from '@fast-ai/dashboard-icons/custom/KeyboardArrowUp';
import Button from '@fast-ai/ui-components/Button';

import { DesktopFlex, LanguageSwitcher, MobileBox } from '../ui';
import n from '../intl/messages/navigation';
import r from '../intl/messages/routes';
import u from '../intl/messages/ui';
import { Link, useTranslateMessage } from '../intl';

import DesktopNavigationMenu from './DesktopNavigationMenu';
import { setSidebarVisibility, useLayoutState } from './layoutState';

const AppBar = props => (
	<Flex
		as="header"
		sx={{
			position: 'relative',
			alignItems: 'center',
			height: theme => theme.Header.height,
			width: '100%',
			justifyContent: 'space-between',
			zIndex: 1,
		}}
		{...props}
	/>
);
const styles = {
	hamburgerWrapper: {
		flexShrink: 0,
		flexGrow: 0,
		overflowX: 'hidden',
		mr: theme => theme.grid.gutters.map(x => -x),
	},
	fill: {
		height: theme => theme.Header.height,
	},
	startLink: {
		alignItems: 'center',
		display: 'flex',
		textDecoration: 'none',
		color: 'inherit',
		flexShrink: 0,
	},
	middle: {
		alignItems: 'center',
		minWidth: 'unset',
	},
};

const HeaderAppBar = ({ basepath, ...rest }) => {
	const [{ sidebarOpened }, dispatchToLayout] = useLayoutState();
	const start = (
		<Link to={r.home} sx={styles.startLink}>
			<Logo />
		</Link>
	);

	const middle = (
		<DesktopFlex sx={styles.middle}>
			<DesktopNavigationMenu basepath={basepath} />
		</DesktopFlex>
	);

	const end = (
		<Fragment>
			<DesktopFlex
				sx={{
					alignItems: 'center',
					gap: 2,
				}}
			>
				<LanguageSwitcher />

				<LinkButton
					as={Link}
					sx={{ p: 2 }}
					variant="outlinePrimary"
					to={r.scheduleDemo}
				>
					<Text sx={{ color: 'inherit' }} variant="nav">
						<FormattedMessage {...n.scheduleDemo} />
					</Text>
				</LinkButton>
			</DesktopFlex>

			<MobileBox sx={styles.hamburgerWrapper}>
				<Hamburger
					isOpen={sidebarOpened}
					onClick={() => {
						dispatchToLayout(setSidebarVisibility(!sidebarOpened));
					}}
				/>
			</MobileBox>
		</Fragment>
	);
	return (
		<AppBar {...rest}>
			{start}
			{middle}
			{end}
		</AppBar>
	);
};

const Header = ({ appBarProps, sx, ...rest }) => {
	const translate = useTranslateMessage();
	const [{ sidebarOpened }] = useLayoutState();
	const isDocumentScrollProgress = useScrollTrigger({
		disableHysteresis: true,
		threshold: 64,
	});

	const isSticky = isDocumentScrollProgress || sidebarOpened;

	const fillForFixedPositionedWrapper = <Box sx={styles.fill} />;

	return (
		<Fragment>
			{fillForFixedPositionedWrapper}
			<Button
				variant="secondary"
				title={translate(u.backToTop)}
				sx={{
					position: 'fixed',
					bottom: '24px',
					right: '24px',
					zIndex: t => t.zIndex.header,
					opacity: isSticky ? '1' : '0',
					p: 2,
				}}
				onClick={() => {
					if (window.scrollTo) {
						window.scrollTo(0, 0);
					}
				}}
			>
				<KeyboardArrowUp />
			</Button>
			<Box
				sx={{
					position: 'fixed',
					backgroundColor: isSticky ? 'rgba(255,255,255,0.7)' : 'transparent',
					borderBottom: isSticky ? theme => theme.borders.normal : 'none',
					backdropFilter: isSticky ? 'blur(20px)' : 'none',
					zIndex: t => t.zIndex.header,
					top: 0,
					left: 0,
					right: 0,
					transition: 'all 0.3s',
					...sx,
				}}
				{...rest}
			>
				<Container>
					<Row>
						<Col span={12}>
							<HeaderAppBar {...appBarProps} />
						</Col>
					</Row>
				</Container>
			</Box>
		</Fragment>
	);
};
export default Header;
