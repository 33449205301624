/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(3 0) scale(1 1)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M11 13h-1a2 2 0 00-2 2v3l-2 2 2 2v3a2 2 0 002 2h1M15 13h1a2 2 0 012 2v3l2 2-2 2v3a2 2 0 01-2 2h-1M9 5v4H1" />
				<path d="M26 1H9L1 9v22h24V12M25 4v2M25 8v2" />
			</g>
		</g>
	</Fragment>,
	32,
	'Editor1Asset19'
);
