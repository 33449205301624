/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const KeyboardArrowUp = forwardRef(({ ...rest }, ref) => (
	<SvgIcon viewBox="0 0 24 24" {...rest} ref={ref}>
		<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
	</SvgIcon>
));

KeyboardArrowUp.displayName = 'KeyboardArrowUp';

export default KeyboardArrowUp;
