const wrapEvent =
	(eventHandler, componentEventHandler) =>
	(event, ...args) => {
		if (eventHandler) {
			eventHandler(event, ...args);
		}
		if (!event.defaultPrevented) {
			return componentEventHandler(event, ...args);
		}
	};
export default wrapEvent;
