/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment } from 'react';
import { Box, Flex } from '@fast-ai/ui-components';
import Github from '@fast-ai/dashboard-icons/src/Github';
import Book from '@fast-ai/dashboard-icons/src/Book';
import ZoeDr from '@fast-ai/dashboard-icons/src/ZoeDr';
import ZoeBaas from '@fast-ai/dashboard-icons/src/ZoeBaas';
import Careers from '@fast-ai/dashboard-icons/src/CharityAndEcology1Asset25';
import References from '@fast-ai/dashboard-icons/src/UsersAndCareers1Asset20';
import ExternalLink from '@fast-ai/ui/ExternalLink';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';

import { Menu, MenuItem } from '../ui';
import { useTranslateMessage } from '../intl/hooks';
import c from '../intl/messages/common';
import n from '../intl/messages/navigation';
import r from '../intl/messages/routes';

import NavigationItemDefault from './NavigationItem';

const NavigationItem = props => (
	<NavigationItemDefault disableHoverBackground {...props} />
);

const SubmenuLink = ({ to, label, sx }) => {
	const translate = useTranslateMessage();
	return (
		<Box
			as={ExternalLink}
			to={translate(to)}
			sx={{
				color: 'body',
				textDecoration: 'none',
				'&:hover,&:focus': {
					color: 'primary',
				},
				...sx,
			}}
		>
			{label}
		</Box>
	);
};
const SubmenuColumns = ({ sx, ...rest }) => (
	<Flex
		sx={{
			pl: theme => theme.MiniGuide.width,
			pb: [2, 2, 3],
			...sx,
		}}
		{...rest}
	/>
);

const SubmenuColumn = ({ to, label, items, sx }) => (
	<Menu
		sx={{
			flexDirection: 'column',
			minWidth: '250px',
			lineHeight: '1.5',
			...sx,
		}}
	>
		{label && (
			<MenuItem>
				<SubmenuLink
					to={to}
					variant="small"
					sx={{ fontWeight: 'bold' }}
					label={label}
				/>
			</MenuItem>
		)}
		{items.map(({ to, label }, i) => (
			<MenuItem key={`${to}${i}`}>
				<SubmenuLink variant="small" to={to} label={label} />
			</MenuItem>
		))}
	</Menu>
);

export default [
	{
		label: <FormattedMessage {...n.products} />,
		children: [
			{
				label: <FormattedMessage {...n.zoeDr} />,
				to: r.dr,
				icon: (
					<Box
						as={ZoeDr}
						sx={{ '.c,.border': { fill: 'primary' } }}
						size={40}
					/>
				),
				description: <FormattedMessage {...n.zoeDrDescription} />,
			},
			{
				label: <FormattedMessage {...n.zoeBass} />,
				to: r.baas,
				icon: (
					<Box
						as={ZoeBaas}
						sx={{ '.q,.border': { fill: 'primary' } }}
						size={40}
					/>
				),
				description: <FormattedMessage {...n.zoeBaasDescription} />,
			},
		].map((props, i) => <NavigationItem key={`${props.to}${i}`} {...props} />),
	},
	{
		label: <FormattedMessage {...n.company} />,
		children: [
			<NavigationItem
				key="company-references"
				{...{
					label: <FormattedMessage {...n.references} />,
					to: r.references,
					icon: <References size={32} />,
					description: <FormattedMessage {...n.referencesDescription} />,
				}}
			/>,
			<NavigationItem
				key="company-careers"
				{...{
					label: <FormattedMessage {...n.careers} />,
					to: r.careers,
					icon: <Careers size={32} />,
					description: <FormattedMessage {...n.careersDescription} />,
				}}
			/>,
		],
	},
	{
		label: <FormattedMessage {...n.forDevelopers} />,
		children: [
			<Fragment>
				<NavigationItem
					key="/docs"
					{...{
						label: <FormattedMessage {...n.documentation} />,
						to: 'https://zoe.lundegaard.ai/docs/',
						icon: <Book size={32} />,
						description: <FormattedMessage {...n.documentationDescription} />,
					}}
				/>
				<SubmenuColumns>
					<SubmenuColumn
						label={<FormattedMessage {...c.dr} />}
						to="https://zoe.lundegaard.ai/docs/dr"
						items={[
							{
								label: <FormattedMessage {...n.documentation} />,
								to: 'http://zoe.lundegaard.ai/docs/dr',
							},
							{
								label: <FormattedMessage {...n.installation} />,
								to: 'https://zoe.lundegaard.ai/docs/dr/learn/s-analytics/getting-started',
							},
							{
								label: <FormattedMessage {...n.saReference} />,
								to: 'https://zoe.lundegaard.ai/docs/dr/api-reference/s-analytics',
							},
							{
								label: <FormattedMessage {...n.apiGuides} />,
								to: 'https://zoe.lundegaard.ai/docs/dr/learn/zoe-api/getting-started',
							},
							{
								label: <FormattedMessage {...n.apiReference} />,
								to: 'https://zoe.lundegaard.ai/docs/dr/api-reference/zoe-api',
							},
						]}
					/>
					<SubmenuColumn
						label={<FormattedMessage {...c.baas} />}
						to="https://zoe.lundegaard.ai/docs/baas"
						items={[
							{
								label: <FormattedMessage {...n.documentation} />,
								to: 'http://zoe.lundegaard.ai/docs/baas',
							},
							{
								label: <FormattedMessage {...n.installation} />,
								to: 'http://zoe.lundegaard.ai/docs/baas/s-analytics/learn/installation',
							},
							{
								label: <FormattedMessage {...n.zoeApi} />,
								to: 'http://zoe.lundegaard.ai/docs/baas/s-analytics/api-reference',
							},
							{
								label: <FormattedMessage {...n.smartFeatures} />,
								to: 'https://zoe.lundegaard.ai/docs/baas/zoe-api/api-reference/features',
							},
							{
								label: <FormattedMessage {...n.smartSignals} />,
								to: 'https://zoe.lundegaard.ai/docs/baas/zoe-api/api-reference/smart-signals',
							},
						]}
					/>
				</SubmenuColumns>
			</Fragment>,
			<Fragment>
				<NavigationItem
					key="github"
					{...{
						label: <FormattedMessage {...n.openSource} />,
						description: <FormattedMessage {...n.openSourceDescription} />,
						to: 'https://github.com/lundegaard',
						icon: <Github size={32} />,
					}}
				/>
				<SubmenuColumns>
					<SubmenuColumn
						items={[
							{
								label: <FormattedMessage {...n.zoeBaasDemo} />,
								to: 'https://github.com/lundegaard/fast-ai-zoe-demo',
							},
							{
								label: <FormattedMessage {...n.zoeGatsbyDemo} />,
								to: 'https://github.com/lundegaard/gatsby-theme-fast-ai',
							},
						]}
					/>
					<SubmenuColumn
						items={[
							{
								label: <FormattedMessage {...n.reactMapy} />,
								to: 'https://github.com/lundegaard/react-mapy-cz',
							},
						]}
					/>
				</SubmenuColumns>
			</Fragment>,
		],
	},
	{
		label: <FormattedMessage {...n.blog} />,
		to: r.blog,
	},
];
