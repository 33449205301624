import React, { createContext, useContext, useReducer } from 'react';

const layoutContext = createContext();

const initialState = {
	sidebarOpened: false,
	sidebarVariant: 'floating',
};
const ActionTypes = {
	SHOW_SIDEBAR: 'SHOW_SIDEBAR',
	HIDE_SIDEBAR: 'HIDE_SIDEBAR',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ActionTypes.SHOW_SIDEBAR: {
			return { ...state, sidebarOpened: true };
		}
		case ActionTypes.HIDE_SIDEBAR: {
			return { ...state, sidebarOpened: false };
		}
	}
	return state;
};

export const showSidebar = () => ({ type: ActionTypes.SHOW_SIDEBAR });
export const hideSidebar = () => ({ type: ActionTypes.HIDE_SIDEBAR });
export const setSidebarVisibility = show =>
	show ? showSidebar() : hideSidebar();

export const LayoutProvider = props => {
	const state = useReducer(reducer, initialState);

	return <layoutContext.Provider {...props} value={state} />;
};

export const useLayoutState = () => useContext(layoutContext);
