import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	scheduleDemo: {
		id: 'nav.scheduleDemo',
		defaultMessage: 'Schedule a demo',
	},
	contacts: {
		id: 'nav.contacts',
		defaultMessage: 'Contacts',
	},
	documentation: { id: 'nav.documentation', defaultMessage: 'Documentation' },
	documentationDescription: {
		id: 'nav.documentationDescription',
		defaultMessage: 'Detailed description for developers.',
	},
	installation: { id: 'nav.installation', defaultMessage: 'Installation' },
	saReference: {
		id: 'nav.saReference',
		defaultMessage: 'S-Analytics reference',
	},
	apiGuides: { id: 'nav.apiGuides', defaultMessage: 'Zoe.ai API guides' },
	apiReference: {
		id: 'nav.apiReference',
		defaultMessage: 'Zoe.ai API reference',
	},
	zoeApi: { id: 'nav.zoeApi', defaultMessage: 'Zoe.ai API' },
	forDevelopers: {
		id: 'nav.forDevelopers',
		defaultMessage: 'For developers',
		description: 'Heading',
	},
	products: {
		id: 'nav.products',
		defaultMessage: 'Products',
		description: 'Heading',
	},
	contactUs: {
		id: 'nav.contactUs',
		defaultMessage: 'Contact us',
		description: 'Heading',
	},
	zoeDr: {
		id: 'nav.zoeDr',
		defaultMessage: 'Zoe.ai Deep recommendation',
	},
	zoeDrDescription: {
		id: 'nav.zoeDrDescription',
		defaultMessage: 'Self-learning recommendation engine.',
	},
	zoeBass: {
		id: 'nav.zoeBass',
		defaultMessage: 'Zoe.ai Behavior-as-a-Service',
	},
	zoeBaasDescription: {
		id: 'zoeBaasDescription',
		defaultMessage: 'Providing insights about user interactions.',
	},

	openSource: { id: 'nav.openSource', defaultMessage: 'Open Source' },
	openSourceDescription: {
		id: 'nav.openSourceDescription',
		defaultMessage: 'Contribute to our open source.',
	},

	smartSignals: { id: 'nav.smartSignals', defaultMessage: 'Smart signals' },
	smartFeatures: { id: 'nav.smartFeatures', defaultMessage: 'Smart features' },

	zoeBaasDemo: {
		id: 'nav.zoeBaasDemo',
		defaultMessage: 'Fast AI Gatsby theme',
	},
	zoeGatsbyDemo: {
		id: 'nav.zoeGatsbyDemo',
		defaultMessage: 'Zoe.ai BaaS Demo',
	},
	reactMapy: {
		id: 'nav.reactMapy',
		defaultMessage: 'React Mapy.cz',
	},
	company: {
		id: 'nav.company',
		defaultMessage: 'Company',
	},
	careers: {
		id: 'nav.careers',
		defaultMessage: 'Careers',
	},
	careersDescription: {
		id: 'nav.careersDescription',
		defaultMessage: 'Browse open positions.',
	},
	references: {
		id: 'nav.references',
		defaultMessage: 'References',
	},
	referencesDescription: {
		id: 'nav.referencesDescription',
		defaultMessage: 'Case studies and success stories of our clients.',
	},
	blog: {
		id: 'nav.blog',
		defaultMessage: 'Blog',
	},
	saGettingStarted: {
		id: 'nav.saGettingStarted',
		defaultMessage: 'Getting started with S-Analytics',
	},
});
