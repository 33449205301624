/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment, useState } from 'react';
import { Button, Col, Row, Text } from '@fast-ai/ui-components';
import Modal from '@fast-ai/ui/Modal';
import { animated, useTransition } from '@react-spring/web';

import { Section } from '../ui';
import { getConsent, grantAll, updateConsent } from '../cookieConsent';

import ManageForm from './CookieBarManageForm';

const AnimatedModal = animated(Modal);
const CookieBar = ({ sx, slideOffset = '100%' }) => {
	const [showBar, setShowBar] = useState(false);
	const [showManageForm, setShowManageForm] = useState(false);

	const transitionStart = {
		opacity: 0,
		transform: `translate3d(0,-${slideOffset},0)`,
	};
	const transition = useTransition(showManageForm, {
		from: transitionStart,
		enter: {
			opacity: 1,
			transform: 'translate3d(0,0,0)',
		},
		leave: transitionStart,
	});

	const onCookieBarRef = () => {
		if (!showBar) {
			// NOTE: can't use useEffect - it is not called after hydratation
			// useEffect(() => {
			const { userShouldFillConsent, ...rest } = getConsent();

			if (userShouldFillConsent) {
				setShowBar(true);
			} else {
				updateConsent(rest);
			}
		}
		// }, []);
	};

	const handleModalClose = () => {
		setShowManageForm(false);
		setShowBar(false);
	};

	return (
		<Fragment>
			<Section
				ref={onCookieBarRef}
				sx={{
					position: 'fixed',
					backgroundColor: 'backgroundPrimary',
					boxShadow: 'nav',
					top: 0,
					left: 0,
					right: 0,
					transform: showBar ? 'translateY(0%)' : 'translateY(-130%)',
					transition: 'transform 500ms',
					zIndex: t => t.zIndex.cookieBar - 1,
				}}
			>
				<Row
					sx={{
						flexWrap: 'wrap',
					}}
				>
					<Col span={[0, 0, 2]} />
					<Col span={[12, 12, 8]} sx={{ mb: 2, textAlign: 'center' }}>
						<Text variant="subHeading1">
							This website uses necessary and analytic cookies to measure
							traffic.
						</Text>
						<Text variant="body" sx={{ mb: 2 }}>
							We may place these for analysis of our visitor data, to improve
							our website and to give you a great website experience. For more
							information about the cookies we use manage the settings.
						</Text>
					</Col>
					<Col span={12} sx={{ textAlign: 'center' }}>
						<Button
							type="button"
							variant="primary"
							sx={{ mr: 2 }}
							onClick={() => {
								grantAll();
								setShowBar(false);
							}}
						>
							Agree
						</Button>
						<Button
							variant="outline"
							onClick={() => {
								setShowBar(false);
								setShowManageForm(true);
							}}
						>
							Manage
						</Button>
					</Col>
				</Row>
			</Section>
			{transition(
				(style, item) =>
					item && (
						<AnimatedModal
							hideBackdrop
							isOpened
							style={style}
							sx={{
								zIndex: t => t.zIndex.cookieBar,
								backgroundColor: 'backgroundPrimary',
								boxShadow: 'nav',
								bottom: '25%',
								'& > div': {
									height: '100%',
								},
								...sx,
							}}
						>
							<ManageForm onClose={handleModalClose} />
						</AnimatedModal>
					)
			)}
		</Fragment>
	);
};
export default CookieBar;
