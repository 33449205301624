import React, { Fragment, cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import getOwnerDocument from '../utils/getOwnerDocument';
import useForkRef from '../utils/useForkRef';

/**
 * Utility component that locks focus inside the component.
 * @see https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Unstable_TrapFocus/Unstable_TrapFocus.js
 */
const TrapFocus = ({
	children,
	disableAutoFocus,
	disableEnforceFocus,
	disableRestoreFocus,
	getDoc,
	isOpened,
}) => {
	const ignoreNextEnforceFocus = useRef();
	const sentinelStart = useRef(null);
	const sentinelEnd = useRef(null);
	const nodeToRestore = useRef();
	const reactFocusEventTarget = useRef(null);
	// This variable is useful when disableAutoFocus is true.
	// It waits for the active element to move into the component to activate.
	const activated = useRef(false);

	const rootRef = useRef(null);
	const handleRef = useForkRef(children.ref, rootRef);

	const prevOpenRef = useRef();
	useEffect(() => {
		prevOpenRef.current = isOpened;
	}, [isOpened]);

	if (
		!prevOpenRef.current &&
		isOpened &&
		typeof window !== 'undefined' &&
		!disableAutoFocus
	) {
		// WARNING: Potentially unsafe in concurrent mode.
		// The way the read on `nodeToRestore` is setup could
		// make this actually safe.
		// Say we render `isOpened={false}` -> `isOpened={true}` but never commit.
		// We have now written a state that wasn't committed.
		// But no committed effect
		// will read this wrong value. We only read from `nodeToRestore` in effects
		// that were committed on `isOpened={true}`
		// WARNING: Prevents the instance from being garbage collected. Should only
		// hold a weak ref.
		nodeToRestore.current = getDoc().activeElement;
	}

	useEffect(() => {
		// We might render an empty child.
		if (!isOpened || !rootRef.current) {
			return;
		}

		activated.current = !disableAutoFocus;
	}, [disableAutoFocus, isOpened]);

	useEffect(() => {
		// We might render an empty child.
		if (!isOpened || !rootRef.current) {
			return;
		}

		const doc = getOwnerDocument(rootRef.current);

		if (!rootRef.current.contains(doc.activeElement)) {
			if (!rootRef.current.hasAttribute('tabIndex')) {
				if (process.env.NODE_ENV !== 'production') {
					// eslint-disable-next-line no-console
					console.error(
						[
							'The modal content node does not accept focus.',
							'For the benefit of assistive technologies, ' +
								'the tabIndex of the node is being set to "-1".',
						].join('\n')
					);
				}
				rootRef.current.setAttribute('tabIndex', -1);
			}

			if (activated.current) {
				rootRef.current.focus();
			}
		}

		return () => {
			// restoreLastFocus()
			if (!disableRestoreFocus) {
				// In IE11 it is possible for document.activeElement to be null
				// resulting in nodeToRestore.current being null.
				// Not all elements in IE11 have a focus method.
				// Once IE11 support is dropped the focus() call can be unconditional.
				if (nodeToRestore.current && nodeToRestore.current.focus) {
					ignoreNextEnforceFocus.current = true;
					nodeToRestore.current.focus();
				}

				nodeToRestore.current = null;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpened]);

	useEffect(() => {
		// We might render an empty child.
		if (!isOpened || !rootRef.current) {
			return;
		}

		const doc = getOwnerDocument(rootRef.current);

		const contain = nativeEvent => {
			const { current: rootElement } = rootRef;
			// Cleanup functions are executed lazily in React 17.
			// Contain can be called between the component being unmounted
			// and its cleanup function being run.
			if (rootElement === null) {
				return;
			}

			if (
				!doc.hasFocus() ||
				disableEnforceFocus ||
				ignoreNextEnforceFocus.current
			) {
				ignoreNextEnforceFocus.current = false;
				return;
			}

			if (!rootElement.contains(doc.activeElement)) {
				// if the focus event is not coming from inside the children's
				// react tree, reset the refs
				if (
					(nativeEvent &&
						reactFocusEventTarget.current !== nativeEvent.target) ||
					doc.activeElement !== reactFocusEventTarget.current
				) {
					reactFocusEventTarget.current = null;
				} else if (reactFocusEventTarget.current !== null) {
					return;
				}

				if (!activated.current) {
					return;
				}

				rootElement.focus();
			} else {
				activated.current = true;
			}
		};

		const loopFocus = nativeEvent => {
			if (disableEnforceFocus || nativeEvent.key !== 'Tab') {
				return;
			}

			// Make sure the next tab starts from the right place.
			if (doc.activeElement === rootRef.current) {
				// We need to ignore the next contain as
				// it will try to move the focus back to the rootRef element.
				ignoreNextEnforceFocus.current = true;
				if (nativeEvent.shiftKey) {
					sentinelEnd.current.focus();
				} else {
					sentinelStart.current.focus();
				}
			}
		};

		doc.addEventListener('focusin', contain);
		doc.addEventListener('keydown', loopFocus, true);

		// With Edge, Safari and Firefox, no focus related events
		// are fired when the focused area stops being a focused area.
		// e.g. https://bugzilla.mozilla.org/show_bug.cgi?id=559561.
		// Instead, we can look if the active element was restored
		// on the BODY element.
		//
		// The whatwg spec defines how the browser should behave but does not
		// explicitly mention any events:
		// https://html.spec.whatwg.org/multipage/interaction.html#focus-fixup-rule.
		const interval = setInterval(() => {
			if (doc.activeElement.tagName === 'BODY') {
				contain();
			}
		}, 50);

		return () => {
			clearInterval(interval);

			doc.removeEventListener('focusin', contain);
			doc.removeEventListener('keydown', loopFocus, true);
		};
	}, [disableAutoFocus, disableEnforceFocus, disableRestoreFocus, isOpened]);

	const onFocus = event => {
		if (!activated.current) {
			nodeToRestore.current = event.relatedTarget;
		}
		activated.current = true;
		reactFocusEventTarget.current = event.target;

		const childrenPropsHandler = children.props.onFocus;
		if (childrenPropsHandler) {
			childrenPropsHandler(event);
		}
	};

	return (
		<Fragment>
			<div tabIndex={0} ref={sentinelStart} data-test="sentinelStart" />
			{cloneElement(children, { ref: handleRef, onFocus })}
			<div tabIndex={0} ref={sentinelEnd} data-test="sentinelEnd" />
		</Fragment>
	);
};

TrapFocus.propTypes = {
	/**
	 * A single child content element.
	 */
	children: PropTypes.node,
	/**
	 * If `true`, the trap focus will not automatically shift focus
	 * to itself when it opens, and
	 * replace it to the last focused element when it closes.
	 * This also works correctly with any trap focus children
	 * that have the `disableAutoFocus` prop.
	 *
	 * Generally this should never be set to `true` as it makes the trap focus
	 * less accessible to assistive technologies, like screen readers.
	 * @default false
	 */
	disableAutoFocus: PropTypes.bool,
	/**
	 * If `true`, the trap focus will not prevent focus from leaving
	 * the trap focus while open.
	 *
	 * Generally this should never be set to `true` as it makes
	 * the trap focus less accessible to assistive technologies,
	 * like screen readers.
	 * @default false
	 */
	disableEnforceFocus: PropTypes.bool,
	/**
	 * If `true`, the trap focus will not restore focus to previously
	 * focused element once
	 * trap focus is hidden.
	 * @default false
	 */
	disableRestoreFocus: PropTypes.bool,
	/**
	 * Return the document to consider.
	 * We use it to implement the restore focus between different browser
	 * documents.
	 */
	getDoc: PropTypes.func.isRequired,
	/**
	 * If `true`, focus is locked.
	 */
	isOpened: PropTypes.bool.isRequired,
};

TrapFocus.displayName = 'TrapFocus';

export default TrapFocus;
